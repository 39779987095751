body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .amplify-label{
  color: white !important;
} */
[data-amplify-authenticator] [data-amplify-router] {
  border-radius: '20px !important';
}
.amplify-input  {
  color: #303030 !important;
}
.amplify-input :focus {
  border-color: #4D74B2 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
